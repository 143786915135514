import React, { CSSProperties, ReactNode } from 'react';

const BUTTON_COLOR = '#008531';

const BUTTON: CSSProperties = {
  WebkitUserSelect: 'none',
  msUserSelect: 'none',
  MozUserSelect: 'none',
  userSelect: 'none',
  fontSize: 16,
  borderRadius: 8,
  padding: '8px 16px 8px 16px',
  outline: 'none',
  border: 'none',
  backgroundColor: BUTTON_COLOR,
  color: 'white',
  minHeight: 48,
  width: '100%',
  maxWidth: '100%',
};

const CURSOR_POINTER: CSSProperties = {
  cursor: 'pointer',
};

const BUTTON_HOVERED: CSSProperties = {
  filter: 'brightness(90%)',
};

const BUTTON_FOCUSED: CSSProperties = {
  filter: 'brightness(90%)',
  outline: 'solid #375a7f 3px',
  outlineOffset: '1px',
};

const BUTTON_REVERSED: CSSProperties = {
  backgroundColor: 'white',
  color: BUTTON_COLOR,
  border: `2px solid ${BUTTON_COLOR}`,
};

const BUTTON_ICON: CSSProperties = {
  width: 20,
  height: 20,
};

export function Button(props: {
  type?: 'button' | 'submit';
  text?: string;
  children?: ReactNode;
  style?: CSSProperties;
  icon?: string;
  title?: string;
  disabled?: boolean;
  reversed?: boolean;
  onClick?: () => void;
}): React.ReactElement {
  const {
    type = 'button',
    text,
    children,
    style,
    icon,
    disabled,
    title,
    reversed = false,
    onClick,
  } = props;
  const [{hovered, focused}, setState] = React.useState({hovered: false, focused: false});

  return (
    <button
      type={type}
      style={{
        ...BUTTON,
        ...(!disabled && CURSOR_POINTER),
        ...(hovered && !disabled && BUTTON_HOVERED),
        ...(reversed && BUTTON_REVERSED),
        ...(focused && !disabled && BUTTON_FOCUSED),
        ...style,
      }}
      disabled={disabled}
      onMouseEnter={() => setState({hovered: true, focused})}
      onMouseLeave={() => setState({hovered: false, focused})}
      onFocus={() => setState({focused: true, hovered})}
      onBlur={() => setState({focused: false, hovered})}
      title={title}
      onClick={onClick}
    >
      {icon ?
        <img src={icon} alt="icon" style={BUTTON_ICON} /> :
        text || children
      }
    </button>
  );
}
