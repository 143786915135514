import { RouteComponentProps } from '@reach/router';

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {View} from '../components';

interface IProps extends RouteComponentProps {
  extraCb?: () => void
}
interface IState {}

const index = Number(localStorage.getItem('loadingIndex')) || 0;
localStorage.setItem('loadingIndex', (index+1).toString())

export default class GameLoading extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    if (this.props.extraCb) {
      setTimeout(this.props.extraCb, (0));
    }
  }

  render() {
    return (
      <View
        flex={true}
        center={true}
        style={{
          padding: 32,
          width: '100%',
          height: '100%',
          position: 'absolute',
          flexDirection: 'column',
        }}
      >
      <Helmet>
        <title>TP Scramble</title>
      </Helmet>
      <h1 style={{fontSize: '1.2rem', textAlign: 'center'}}>Loading...</h1>
    </View>);
  }

}
