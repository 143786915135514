export const DISABLE_SELECT = {

};

export const IMAGE_BUTTON = {
  alignItems: 'center',
  verticalAlign : 'middle',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '4px 8px 4px 8px',
  backgroundColor: '#375a7f',
};
