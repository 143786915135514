import React, { Component, lazy, Suspense } from 'react';

import './index.css';

import GameLoading from './scenes/GameLoading';
const Root = lazy(() => import("./Root"));

class App extends Component {

  render() {
    return (
      <Suspense fallback={<GameLoading/>} >
        <Root/>
      </Suspense>
    );
  }

}

export default App;
