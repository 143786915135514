import React, { CSSProperties } from 'react';
import {syringePath} from '../images/textures/prop';
import {Button, View} from './';

import {IMAGE_BUTTON} from '../utils/classes';

export function CurrencyCount(props: {
  onClick?: () => void;
  disabled?: boolean;
  count: string;
  style?: CSSProperties;
}): React.ReactElement {
  const {
    onClick,
  //  disabled,
    count,
    style,
  } = props;
  return (
    <View flex={true} style={{...style}}>
      <Button disabled={true} onClick={onClick} style={{...IMAGE_BUTTON}}>
      <img style={{height: '30px'}} alt='Toilet paper' src={syringePath}/>
      <div style={{lineHeight: '30px'}}>{count}</div>
      </Button>
    </View>
  );
}
