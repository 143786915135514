import log from 'loglevel';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

if (process.env.NODE_ENV === 'development') {
  log.setLevel('info');
} else {
  log.setLevel('error');
}

(window as any).log = log;

ReactDOM.render(<App />, document.getElementById('root'));
