import { Texture } from 'pixi.js';

import beer from './beer.png';
import handsanitizer from './healthpack.png';
import syringePath from './syringe.png';

const syringeTexture: Texture = Texture.from(syringePath);
const handsanitizerTexture: Texture = Texture.from(handsanitizer);
const beerbottleTexture: Texture = Texture.from(beer);


export {
  syringePath,
  syringeTexture,
  handsanitizerTexture,
  beerbottleTexture,
};
