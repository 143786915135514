import {navigate} from '@reach/router';
import {Types} from '@tp-scramble/common';
import React, { Component } from 'react';
import {AccountService} from '../services/account';
import {Box, Button, CurrencyCount, Space, View} from './';

import {Subscription} from 'rxjs';

interface IProps {
  auth: Types.AuthState;
  accountService: AccountService;
  loggedOutText: string;
  anonymousLoggedText: string;
}

interface IState {
  currency: number | 'zero';
}

export class LoginState extends Component<IProps, IState> {
  public state: IState = {
    currency: 'zero',
  };

  private currencySubscription: Subscription;
  componentDidMount() {
    this.currencySubscription = this.props.accountService.currency$.subscribe(currency => {
      this.setState({
        currency: currency,
      });
    });
  }
  componentWillUnmount() {
    this.currencySubscription?.unsubscribe();
  }

  handleLogout = async () => {
    this.props.accountService.logout();
  }

  findUserName = (auth: Types.AuthUser): string => {
    if (auth === null) {
      return 'Error';
    }

    if (auth.isAnonymous) {
      return 'Anonymous';
    }

    if (auth.email) {
      return auth.email;
    }

    if (auth.displayName) {
      return auth.displayName;
    }

    return '';
  }

  render() {
    if (this.props.auth === 'zero') {
      return this.renderZeroState();
    }
    if (this.props.auth === null) {
      return this.renderLoggedOut();
    }
    const isAnonymous = this.props.auth.isAnonymous;
    return (
      <Box
      >
        <View flex={true} column={true}>
          {!isAnonymous && <p style={{fontSize: '0.8rem', marginBottom: '0.5rem', wordBreak: 'break-all'}}>{this.findUserName(this.props.auth)}</p>}
          {isAnonymous && <p style={{fontSize: '0.8rem', marginBottom: '0.5rem', textAlign: 'center'}}>{this.props.anonymousLoggedText}</p>}
          <Space size='xxs'/>
          <View flex={true} style={{justifyContent: 'space-between', width: '100%'}} column={true} >
              <CurrencyCount
                style={{
                  width: '100%',
              }} count={this.state.currency === 'zero' ? 'Loading...' : this.state.currency.toString()} />
              <Space size='xs'/>
              {!isAnonymous && <Button onClick={this.handleLogout} style={{width: '100%'}}>Logout</Button>}
              {isAnonymous && <Button onClick={() => navigate('/login')} style={{width: '100%'}}>Login</Button>}
          </View>
        </View>
      </Box>
    );
  }

  renderLoggedOut() {
    return (
      <Box
      >
        <View flex={true} column={true}>
          <p style={{fontSize: '0.8rem', marginBottom: '0.5rem', textAlign: 'center'}}>{this.props.loggedOutText}</p>
          <Space size='xxs'/>
          <View flex={true} style={{justifyContent: 'space-between', width: '100%'}} column={true} >
              <Button onClick={() => navigate('/login')} style={{width: '100%'}}>Login</Button>
          </View>
        </View>
      </Box>
    );
  }

  renderZeroState() {
    return (
      <Box
      >
        <View flex={true} column={true}>
          <p style={{fontSize: '0.8rem', marginBottom: '0.5rem', textAlign: 'center'}}>Loading...</p>
          <Space size='xxs'/>
          <CurrencyCount
            style={{
              width: '100%',
          }} count={"Loading"} />
          <Space size='xxs'/>
          <View flex={true} style={{justifyContent: 'space-between', width: '100%'}} column={true} >
              <Button disabled={true} onClick={() => navigate('/login')} style={{width: '100%'}}>Loading...</Button>
          </View>
        </View>
      </Box>
    );
  }

}
